import { useCallback } from 'react';
import { Weddings } from '@bridebook/models';
import type { IWedding_Website } from '@bridebook/models/source/models/Weddings.types';
import { type QueryClient, type UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useSelector } from 'lib/utils';
import { getWeddingId } from 'lib/weddings/selectors';

export const WEDDING_WEBSITE_ROOT_QUERY_KEY = 'wedding-website';

export type TWeddingWebsiteQueryData = ReturnType<
  typeof useWeddingWebsite<IWedding_Website | null>
>['data'];

export const useWeddingWebsite = <TSelected = IWedding_Website | null>(
  options?: Partial<UseQueryOptions<IWedding_Website | null, Error, TSelected>>,
) => {
  const weddingId = useSelector(getWeddingId);
  const { data, isLoading, isError, error } = useQuery<IWedding_Website | null, Error, TSelected>(
    [WEDDING_WEBSITE_ROOT_QUERY_KEY, 'single', weddingId],
    async () => {
      const wedding = await Weddings._.getById(weddingId).get();
      return wedding.website ?? null;
    },
    {
      enabled: !!weddingId,
      cacheTime: Infinity, // realtime updates we can cache forever
      staleTime: Infinity,
      ...options,
    },
  );

  return {
    data,
    isLoading,
    isError,
    error,
  };
};

export const useInvalidateWeddingWebsite = () => {
  const invalidate = useCallback((weddingId: string) => {
    if (weddingId) {
      // no need to invalidate, data is updated realtime
      // queryClient.invalidateQueries([WEDDING_WEBSITE_ROOT_QUERY_KEY, 'single', weddingId]);
    }
  }, []);

  return { invalidate };
};

export const updateWeddingWebsiteCache = (
  queryClient: QueryClient,
  weddingId: string,
  weddingWebsite: IWedding_Website | undefined,
) => {
  queryClient.setQueryData<IWedding_Website | undefined>(
    [WEDDING_WEBSITE_ROOT_QUERY_KEY, 'single', weddingId],
    weddingWebsite,
  );
};
